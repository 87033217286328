import './footer.scss';

import React from 'react';

export const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <div className="footer grid">
      <span className="center bottom">&copy; {year}, Anton Podolsky. All Rights Reserved.</span>
    </div>
  );
};

import './content.scss';

import React from 'react';
import { Strip } from '../strip/strip';
import { Gallery } from '../gallery/gallery';
import { Button } from '../button/button';
import { AddonLinks } from '../addon-links/addon-links';

export const Content = () => {
  return (
    <div className="content grid">
      <div className="strips-container grid">
        <div className="strips x3 grid center">
          <Strip icon="cursor">
            <div className="grid gap x2">
              <h2 className="title">Easily access the info</h2>
              <h4 className="subtitle">Exify displays an EXIF info overlay which is triggered by hovering your mouse over an image.</h4>
              <div>
                <a href="#try">
                  <Button>
                    <div className="align space x05">
                      <span>Try it</span>
                      <i className="icon">arrow_right_alt</i>
                    </div>
                  </Button>
                </a>
              </div>
            </div>
          </Strip>

          <div className="separator"></div>

          <Strip icon="camera">
            <div className="grid gap x2">
              <h2 className="title">Focus on the essentials</h2>
              <h4 className="subtitle">
                Exify allows photographers to see the equipment and the essential settings that where used to capture a photo:
              </h4>

              <div className="camera-info grid c2 gap x2">
                <div className="space v x05">
                  <h5 className="text--bold">Default info</h5>
                  <h5>
                    <ul>
                      <li className="align space x05">
                        <i className="icon green">check</i>
                        <span>Camera / Lens</span>
                      </li>
                      <li className="align space x05">
                        <i className="icon green">check</i>
                        <span>Focal length</span>
                      </li>
                      <li className="align space x05">
                        <i className="icon green">check</i>
                        <span>Aperture</span>
                      </li>
                      <li className="align space x05">
                        <i className="icon green">check</i>
                        <span>Exposure</span>
                      </li>
                      <li className="align space x05">
                        <i className="icon green">check</i>
                        <span>ISO</span>
                      </li>
                    </ul>
                  </h5>
                </div>

                <div className="space v x05">
                  <h5 className="text--bold">Optional info</h5>
                  <h5>
                    <ul>
                      <li className="align">
                        <span>Metering mode</span>
                      </li>
                      <li className="align">
                        <span>Exposure mode / bias</span>
                      </li>
                      <li className="align">
                        <span>Date taken</span>
                      </li>
                      <li className="align">
                        <span>Location</span>
                      </li>
                      <li className="align">
                        <span>Software</span>
                      </li>
                    </ul>
                  </h5>
                </div>
              </div>
            </div>
          </Strip>
          
          <div className="separator"></div>
          
          <Strip icon="rocket">
            <div className="grid gap x2">
              <h2 className="title">Beyond EXIF</h2>
              <h4 className="subtitle">In addition to displaying EXIF metadata, Exify also shows grayscale and RGB histograms of the image.</h4>
            </div>
          </Strip>
        </div>
      </div>

      <div>
        <a id="try"></a>
        <Gallery/>
      </div>

      <div className="strips-container grid">
        <div className="strips grid center">
          <Strip>
            <div className="grid gap x3">
              <h4 className="subtitle center">Get Exify from the official store</h4>

              <AddonLinks className="center"/>
            </div>
          </Strip>
        </div>
      </div>
    </div>
  );
};

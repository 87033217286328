import './addon-stats.scss';

import React, { useEffect, useState } from 'react';

interface Props {
  browser: 'chrome' | 'firefox';
}

const Browsers = {
  chrome: {
    stat: (name: 'rating' | 'users') => `https://shields.io/chrome-web-store/${name}/llhmhhnpmiikplpnkmbgbgmlenleecle.json`,
  },
  firefox: {
    stat: (name: 'rating' | 'users') => `https://shields.io/amo/${name}/exify.json`,
  }
};

export const AddonStats = (props: Props) => {
  const browser = Browsers[props.browser];
  const [enabled, setEnabled] = useState<any>(true);
  const [stats, setStats] = useState<any>([]);
  const [animationPhase, setAnimationPhase] = useState(0);

  useEffect(() => {
    if (!enabled) {
      // setTimeout(() => setEnabled(true), 300);
      return;
    }

    let urls = [browser.stat('rating'), browser.stat('users')];

    Promise.all(urls.map(url => fetch(url).then(res => res.json())))
      .then((res: any) => {
        const statsRes = res.reduce((res: any, {name, value}: any) => ({...res, [name]: value}), {});
        statsRes.stars = Math.ceil(statsRes.rating.split('/')[0]);

        setStats(statsRes);

        return statsRes;
      })
      .then(({stars}) => new Array(stars + 1).fill('').reduce((p, _, index) => p.then(() => new Promise(resolve => setTimeout(() => {
        setAnimationPhase(index + 1);
        resolve(null);
      }, 100))), Promise.resolve()));
  }, [enabled]);

  return <div className="addon-stats">
    {enabled && 
      <div className="align space x05">
        <span>
          { new Array(stats.stars).fill('').map((_, index) =>
            <span className={`addon-stats-star ${index < animationPhase ? 'addon-stats-star--active' : ''} fade-in`} key={index}>★</span>
          )}
        </span>

        {animationPhase > stats.stars && <span className="fade-in">{stats.rating}</span>}
      </div>
    }
  </div>
};

import './app.scss';

import React from 'react';
import ReactDOM from 'react-dom';
import { Header } from './components/header/header';
import { Content } from './components/content/content';
import { Footer } from './components/footer/footer';

import(/* webpackChunkName: "exify" */ 'exify-web' as any);

const App = () => {
  return (
    <div className="app">
      <Header/>
      <a id="more"></a>
      <Content/>
      <Footer/>
    </div>
  );
};

ReactDOM.render(<App />, document.getElementById('app'));

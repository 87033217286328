import './addon-links.scss';

import React from 'react';
import { AddonButton } from '../addon-button/addon-button';

export const AddonLinks = (props: any) => {
  return (
    <div className={`addon-links align space x2 ${props.className}`}>
      <AddonButton browser="chrome"></AddonButton>
      <AddonButton browser="firefox"></AddonButton>
    </div>
  );
};

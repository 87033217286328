import './strip.scss';

import React from 'react';

export const Strip = (props: any) => {
  return (
    <div className="strip">
      <div className="grid gap x5">
        {props.icon ? <img className="strip-image center" src={`https://res.cloudinary.com/hizqiyn6g/image/upload/v1601808922/${props.icon}.webp`} loading="lazy"></img> : ''}
        {props.children}
      </div>
    </div>
  );
};

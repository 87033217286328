import './info.scss';

import React from 'react';

export const Info = (props: any) => {
  return (
    <div className={`info align ${props.className}`}>
      <div className="info-content">{props.children}</div>
      <div className="info-delimiter"></div>
    </div>
  );
};

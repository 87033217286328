import './gallery.scss';

import React from 'react';

export class Gallery extends React.Component {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className="gallery grid">
        <div className="grid gap x2 fixed-content">
          <h2 className="title center">Hover the images bellow for a demo</h2>
          <h4 className="subtitle center">(Click the logo in the overlay to access the settings dialog)</h4>
        </div>

        <div className="images fixed-grid center">
          <img src="/assets/images/gallery/1.jpg" alt="..." loading="lazy"></img>
          <img src="/assets/images/gallery/2.jpg" alt="..." loading="lazy"></img>
          <img src="/assets/images/gallery/3.jpg" alt="..." loading="lazy"></img>
          <img src="/assets/images/gallery/4.jpg" alt="..." loading="lazy"></img>
        </div>
      </div>
    );
  };
}

export const fadeInBgImage = (ref: React.RefObject<Element>) => {
  const src = window.getComputedStyle(ref.current!).backgroundImage.slice(4, -1).replace(/['"]/g, '');

  if (!src) {
    return;
  }

  const img = new Image();

  img.src = src;
  img.onload = () => ref.current?.classList.add('fade-in');
};

export const showAfterScrollingPast = (trigger: React.RefObject<Element>, target: React.RefObject<Element>) => {
  window.addEventListener('scroll', () => {
    const {y} = trigger.current?.getBoundingClientRect() as DOMRect;
    
    target.current?.classList.toggle('show', y < 0);
  });
}

import './addon-links-with-stats.scss';

import React from 'react';
import { AddonButton } from '../addon-button/addon-button';
import { AddonStats } from '../addon-stats/addon-stats';

export const AddonLinksWithStats = (props: any) => {
  return (
    <div className={`addon-links-with-stats align space x2 ${props.className}`}>
      <div>
        <AddonButton browser="chrome"></AddonButton>
        <AddonStats browser="chrome"></AddonStats>
      </div>
      
      <div>
        <AddonButton browser="firefox"></AddonButton>
        <AddonStats browser="firefox"></AddonStats>
      </div>
    </div>
  );
};

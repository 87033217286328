import './button.scss';

import React from 'react';

export const Button = (props: any) => {
  return (
    <button className={`align space ${props.className}`}>
      {props.icon ? <img src={`https://res.cloudinary.com/hizqiyn6g/image/upload/v1601808922/${props.icon}.webp`} loading="lazy"></img> : ''}
      <span>{props.children}</span>
    </button>
  );
};

import './addon-button.scss';

import React from 'react';
import { Button } from '../button/button';

interface Props {
  browser: 'chrome' | 'firefox';
}

const Browsers = {
  chrome: {
    name: 'chrome',
    title: 'Chrome',
    url: 'https://chrome.google.com/webstore/detail/exify/llhmhhnpmiikplpnkmbgbgmlenleecle',
  },
  firefox: {
    name: 'firefox',
    title: 'Firefox',
    url: 'https://addons.mozilla.org/en-US/firefox/addon/exify/',
  }
};

export const AddonButton = (props: Props) => {
  const browser = Browsers[props.browser];

  return (
    <a
      className="addon-button align"
      href={browser.url}
      target="_blank"
      title={`Get Exify for ${browser.title}`}
    >
      <Button className="orange" icon={browser.name}>{`Add to ${browser.title}`}</Button>
    </a>
  );
};

import './header.scss';

import React from 'react';
import { fadeInBgImage } from '../../lib/utils';
import { Info } from '../info/info';
import { Button } from '../button/button';
import { AddonLinksWithStats } from '../addon-links-with-stats/addon-links-with-stats';

export class Header extends React.Component {
  private bg: React.RefObject<HTMLDivElement>;


  constructor(props: any) {
    super(props)
    
    this.bg = React.createRef();
  }

  componentDidMount() {
    fadeInBgImage(this.bg);
  }

  render()  {
    return (
      <div className="header">
        <div className="header-bg" ref={this.bg}></div>
          
        <div className="header-content fixed-content grid">
          <div className="header-top grid c2 spread fade-in">
            <h1>
              <a href="/">
                <img className="logo" src={`https://res.cloudinary.com/hizqiyn6g/image/upload/v1601808935/logo.webp`}></img>
              </a>
            </h1>

            <div className="menu space x15">
              <a href="https://twitter.com/exify_addon" target="_blank" title="Twitter">
                <img src={`https://res.cloudinary.com/hizqiyn6g/image/upload/v1601808922/twitter.webp`}></img>
              </a>
            </div>
          </div>

          <div className="header-middle grid center gap x2 slide-in">
            <div className="grid gap x2">
              <h1 className="title">EXIF viewer add-on</h1>
              <h3 className="subtitle muted">Modern, fast, designed for photographers.</h3>
              <div className="separator"></div>
            </div>

            <div className="grid gap">
              <AddonLinksWithStats/>
            </div>
          </div>

          <div className="header-bottom grid center fade-in">
            <div className="grid gap x3">
              <div className="align">
                <Info>Free</Info>
                <Info>No ads</Info>
                <Info>No tracking</Info>
              </div>

              <a className="center" href="#more">
                <Button className="outline">
                  <div className="align space x05">
                    <span>Learn more</span>
                    <i className="icon">arrow_right_alt</i>
                  </div>
                </Button>
              </a>
            </div>
          </div>
        </div>
      </div>
    );
  }
};
